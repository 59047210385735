import React from "react"
import { TweenMax, Power3 } from "gsap/TweenMax"
import raf from 'raf'
import { InView } from 'react-intersection-observer'
import "./ChapterIntroImage.scss"

const { detect } = require('detect-browser')
const browser = detect()

class ChapterIntroImage extends React.Component {
  constructor(props) {
    super(props)

    this.rows = 12
    this.playing = false
    this.initialised = false

    this.sizes = []

    for (var x = 0; x < this.rows; x++) {
      this.sizes.push({
        s: 0
      })
    }

    this.maxFps = 20

    this.now = null
    this.delta = null

    this.then = Date.now()
    this.interval = 1000 / this.maxFps

    this.visible = false
    this.revert = false
  }

  componentDidMount() {
    this.requireCanvas = window.innerWidth >= 1240

    if (browser) {
      if (browser.name === "safari") {
        this.requireCanvas = false
        this.revert = true
      }
    }
  }

  componentWillUnmount() {
    if (!this.requireCanvas) return
    clearTimeout(this.stopRafTimeout)
    raf.cancel(this.raf)
    window.removeEventListener('resize', this.resizeCanvas)
  }

  resizeCanvas = () => {
    if (!this.requireCanvas) return

    this.cSize = {
      w: this.mount.offsetWidth,
      h: this.mount.offsetWidth * this.aspectRatio
    }

    this.canvas.width = this.cSize.w * 2
    this.canvas.height = this.cSize.h * 2
    this.canvas.style.width = this.cSize.w + "px"
    this.canvas.style.height = this.cSize.h + "px"
    this.ctx.scale(2, 2)

    // Turn off smoothing
    this.ctx.mozImageSmoothingEnabled = false
    this.ctx.webkitImageSmoothingEnabled = false
    this.ctx.imageSmoothingEnabled = false

    this.shadowCanvas.width = this.cSize.w * 2
    this.shadowCanvas.height = this.cSize.h * 2
    this.shadowCanvas.style.width = this.cSize.w + "px"
    this.shadowCanvas.style.height = this.cSize.h + "px"
    this.shadowCtx.scale(2, 2)

    this.drawImageOnCanvas()
  }

  init = () => {
    if (!this.requireCanvas) return

    this.imageUrl = this.props.url

    this.createCanvas()
      .then(this.createShadowCanvas())
      .then(() => {
        this.loadImage()
      })
  }

  createCanvas = () => {
    return new Promise((resolve, reject) => {
      this.canvas = document.createElement('canvas')

      this.ctx = this.canvas.getContext('2d')
      this.ctx.scale(2, 2)

      // Turn off smoothing
      this.ctx.mozImageSmoothingEnabled = false
      this.ctx.webkitImageSmoothingEnabled = false
      this.ctx.imageSmoothingEnabled = false

      this.mount.appendChild(this.canvas)
      resolve(true)
    })
  }

  createShadowCanvas = () => {
    return new Promise((resolve, reject) => {
      this.shadowCanvas = document.createElement('canvas')

      this.shadowCtx = this.shadowCanvas.getContext('2d')
      this.shadowCtx.scale(2, 2)
      resolve(true)
    })
  }

  loadImage = () => {
    return new Promise((resolve, reject) => {
      let img = new Image()

      img.crossOrigin = ''
      img.src = this.imageUrl

      this.image = img

      img.onload = () => {
        this.imageSize = {
          w: img.width / 2,
          h: img.height / 2
        }

        this.setCanvasSizes()
          .then(this.drawImageOnCanvas())
          .then(() => {
            window.addEventListener('resize', this.resizeCanvas)
          })
          .then(() => {
            this.startRaf()
          })
          .then(() => {
            this.countIn = 0
            TweenMax.staggerTo(this.sizes, 2, {ease: Power3.easeIn, onComplete: this.fullyRenderLine, s: .9, clearProps: 'all'}, .12)
            return true
          })
      }
    })
  }

  setCanvasSizes = () => {
    return new Promise((resolve, reject) => {
      this.aspectRatio = this.imageSize.h / this.imageSize.w

      if (!this.mount) return

      this.cSize = {
        w: this.mount.offsetWidth,
        h: this.mount.offsetWidth * this.aspectRatio
      }

      this.canvas.width = this.cSize.w * 2
      this.canvas.height = this.cSize.h * 2
      this.canvas.style.width = this.cSize.w + "px"
      this.canvas.style.height = this.cSize.h + "px"
      this.ctx.scale(2, 2)

      // Turn off smoothing
      this.ctx.mozImageSmoothingEnabled = false
      this.ctx.webkitImageSmoothingEnabled = false
      this.ctx.imageSmoothingEnabled = false

      this.shadowCanvas.width = this.cSize.w * 2
      this.shadowCanvas.height = this.cSize.h * 2
      this.shadowCanvas.style.width = this.cSize.w + "px"
      this.shadowCanvas.style.height = this.cSize.h + "px"
      this.shadowCtx.scale(2, 2)

      resolve(true)
    })
  }

  fullyRenderLine = () => {
    if (this.sizes[this.countIn]) this.sizes[this.countIn].s = 1
    this.countIn++

    if (this.countIn === this.rows) this.endInitialAnimation()
  }

  endInitialAnimation = () => {
    this.stopRaf()
    this.drawImageOnCanvas()
    this.initialised = true
  }

  drawImageOnCanvas = () => {
    if (!this.cSize) return

    this.ctx.clearRect(0, 0, this.cSize.w, this.cSize.h)

    for (var x = 0; x < this.rows; x++) {
      const w = Math.ceil(this.cSize.w * this.sizes[x].s),
            h = Math.ceil((this.cSize.h / this.rows) * this.sizes[x].s)

      const canvasRowHeight = Math.ceil(this.cSize.h / this.rows),
            imageRowHeight = Math.ceil(this.imageSize.h * 2 / this.rows)

      const imageStartY = (this.imageSize.h * 2 / this.rows) * x

      // Clear the shadow canvas
      this.shadowCtx.clearRect(0, 0, this.cSize.w, this.cSize.h)

      // Draw the image at the reduced size
      this.shadowCtx.drawImage(this.image, 0, imageStartY, this.imageSize.w * 2, imageRowHeight, 0, (canvasRowHeight * x), w, h)

      let useWidth = Math.ceil(w * 2) - 1,
          useHeight = Math.ceil(h * 2) - 1

      // Draw the canvas back on to itself at full size
      this.ctx.drawImage(this.shadowCanvas, 0, (canvasRowHeight * x) * 2, useWidth, useHeight, 0, (canvasRowHeight * x), this.cSize.w, canvasRowHeight)
    }
  }

  animate = () => {
    if (!this.playing) return

    this.now = Date.now()
    this.delta = this.now - this.then

    if (this.delta > this.interval) {
      this.drawImageOnCanvas()
      this.then = this.now - (this.delta % this.interval)
    }

    this.raf = raf(this.animate)
  }

  startRaf = () => {
    clearTimeout(this.stopRafTimeout)
    raf.cancel(this.raf)
    this.playing = true
    this.raf = raf(this.animate)
  }

  countdownToStop = () => {
    this.stopRafTimeout = setTimeout(this.stopRaf, 3000)
  }

  stopRaf = () => {
    this.playing = false
    raf.cancel(this.raf)
  }

  imageInView = (inView, entry) => {
    if (!inView || !this.requireCanvas) return

    this.init()
  }

  render() {

    return (
      <div className="chapter-intro__col chapter-intro__col--image col t4" data-revert={this.revert}>
        <InView
          onChange={this.imageInView}
          threshold={[.4]}
          triggerOnce={true}
        >
          {({ inView, ref }) => (
            <div className="chapter-intro__image" data-anim={inView} ref={ref}>
              <div className="chapter-intro__image-inner">
                <img src={this.props.urlMobile} alt="" />
                <div
                  className="chapter-image-canvas"
                  ref={mount => (this.mount = mount)}
                />
              </div>
            </div>
          )}
        </InView>
      </div>
    )
  }
}

export default ChapterIntroImage