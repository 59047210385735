import React from "react"
import { InView } from 'react-intersection-observer'
import "./Footer.scss"

// Images
import Twitter from "./images/twitter.svg"
import Facebook from "./images/facebook.svg"
import LinkedIn from "./images/linkedin.svg"

class Footer extends React.Component {

  render() {

    return (
      <InView
        threshold={.35}
        triggerOnce={true}
      >
        {({ inView, ref }) => (
          <footer className="site-footer" ref={ref} data-anim={inView}>
            <div className="container">
              <div className="cols">
                <div className="site-footer__col col m12">
                  <div className="site-footer__wrap">
                    <ul className="site-footer__social-links">
                      <li>
                        <a href="https://twitter.com/nesta_uk" target="_blank" rel="noopener noreferrer" title="Visit Nesta on Twitter">
                          <img
                            src={Twitter}
                            width="19"
                            height="15"
                            alt="Visit Nesta on Twitter"
                          />
                          <span>Visit Nesta on Twitter</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/nesta.uk" target="_blank" rel="noopener noreferrer" title="Visit Nesta on Facebook">
                          <img
                            src={Facebook}
                            width="9"
                            height="18"
                            alt="Visit Nesta on Facebook"
                          />
                          <span>Visit Nesta on Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/nesta" target="_blank" rel="noopener noreferrer" title="Visit Nesta on LinkedIn">
                          <img
                            src={LinkedIn}
                            width="19"
                            height="18"
                            alt="Visit Nesta on LinkedIn"
                          />
                          <span>Visit Nesta on LinkedIn</span>
                        </a>
                      </li>
                    </ul>
                    <ul className="site-footer__links">
                      <li>
                        <a href="https://www.nesta.org.uk/press/" target="_blank" rel="noopener noreferrer">Press</a>
                      </li>
                      <li>
                        <a href="https://www.nesta.org.uk/jobs/" target="_blank" rel="noopener noreferrer">Jobs</a>
                      </li>
                      <li>
                        <a href="https://www.nesta.org.uk/privacy/" target="_blank" rel="noopener noreferrer">Privacy</a>
                      </li>
                      <li>
                        <a href="https://www.nesta.org.uk/cookies/" target="_blank" rel="noopener noreferrer">Cookies</a>
                      </li>
                      <li>
                        <a href="https://www.nesta.org.uk/terms-use/" target="_blank" rel="noopener noreferrer">Terms of use</a>
                      </li>
                      <li>
                        <a href="https://www.nesta.org.uk/contact-us/" target="_blank" rel="noopener noreferrer">Contact us</a>
                      </li>
                      <li className="site-footer__accessibility-link">
                        <a href="/text" target="_blank" rel="noopener noreferrer">Accessibility Mode</a>
                      </li>
                      <li className="site-footer__no-accessibility-link">
                        <a href="/" target="_blank" rel="noopener noreferrer">Leave Accessibility Mode</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="site-footer__col col m12 d10">
                  <div className="site-footer__wrap">
                    <p>&copy; 2019 <a href="https://www.nesta.org.uk/" target="_blank" rel="noopener noreferrer">Nesta</a> is a registered charity in England and Wales 1144091 and Scotland SC042833. Our main address is 58 Victoria Embankment, London, EC4Y 0DS.</p>
                    <p>You can reach us by phone on 020 7438 2500 or drop us a line at <a href="mailto:information@nesta.org.uk">information@nesta.org.uk</a>.</p>
                    <p>This work is licensed under a <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License (CC BY-NC-SA 4.0)</a>, unless it says otherwise. We hope you find it useful.</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
      </InView>
    )
  }
}

export default Footer