import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import { Link } from 'react-scroll'
import "./CustomLink.scss"

const CustomLink = (props) => {
  const {label} = props,
        {target} = props,
        {title} = props,
        {modifier} = props,
        {anchor} = props,
        {clickEvent} = props

  const createScrollSpeed = (px) => {
    let speed = px / 4
    speed = (speed < 2000 && 2000)

    return speed
  }

  if (anchor) {

    const {anchorSpeed} = props,
          {offset} = props

    return (
      <Link
        to={target}
        aria-label={label}
        className={modifier && modifier}
        title={title}
        smooth="easeInOutCubic"
        duration={anchorSpeed ? anchorSpeed : createScrollSpeed}
        offset={offset ? offset : 0}
        onClick={clickEvent && clickEvent}
      >
        <span dangerouslySetInnerHTML={{__html: label}} />
      </Link>
    )
  } else {
    return (
      <TransitionLink
        className={modifier && modifier}
        to={target}
        title={title}
        onClick={clickEvent && clickEvent}
      >
        <span dangerouslySetInnerHTML={{__html: label}} />
      </TransitionLink>
    )
  }
}

export default CustomLink
