import React from "react"
import "./TimelineDate.scss"

class TimelineDate extends React.Component {

  render() {
    const {yPos} = this.props,
          {year} = this.props

    return (
      <>
        <circle cx="4200" cy="80" r="10" fill="#fb998e" stroke="none" />
        <text className="timeline__circle-text" x="4166" y={yPos}>{year}</text>
      </>
    )
  }
}

export default TimelineDate