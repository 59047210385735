import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import { Link } from 'react-scroll'
import ArrowBlue from "./images/arrow-story-bottom.png"
import ArrowWhite from "./images/arrow-blue.png"
import ArrowDown from "./images/arrow-down.png"
import "./ArrowBtn.scss"

class ArrowBtn extends React.Component {

  createScrollSpeed(px) {
    return 500
  }

  render() {
    const {label} = this.props,
          {modifier} = this.props,
          {direction} = this.props,
          {target} = this.props,
          {title} = this.props,
          {anchor} = this.props

    const downBool = (direction === 'down' ? true : false),
          imageColor = (modifier === 'white' ? ArrowWhite : ArrowBlue),
          image = (downBool ? ArrowDown : imageColor),
          width = (downBool ? 82 : 165),
          height = (downBool ? 109 : 105)

    if (anchor) {

      const {anchorSpeed} = this.props

      return (
        <Link
          to={target}
          aria-label={label}
          className={modifier ? `arrow arrow--${modifier}` : "arrow"}
          title={title}
          smooth="easeInOutCubic"
          duration={anchorSpeed ? anchorSpeed : this.createScrollSpeed}
          onClick={this.handleAnchorLink}
        >
          <img src={image} title={title} width={width} height={height} alt={title} />
        </Link>
      )

    } else {

      return (
        <TransitionLink
          to={target}
          aria-label={label}
          className={modifier ? `arrow arrow--${modifier}` : "arrow"}
          title={title}
          exit={{
            length: 1
          }}
          entry={{
            delay: 0
          }}
        >
          <img src={image} title={title} width={width} height={height} alt={title} />
        </TransitionLink>
      )

    }
  }
}

export default ArrowBtn