import React from "react"
import { TweenMax, Power3 } from "gsap/TweenMax"
import raf from 'raf'
import "./TimelineImages.scss"

class TimelineImages extends React.Component {
  constructor(props) {
    super(props)

    this.cSize = {
      w: 208,
      h: 208
    }

    this.images = []
    this.playing = false

    /*
      .01 = Very Pixelated
      1 = Not Pixelated
    */

    this.imageVars = {
      imagesLoaded: false,
      initialised: false,
      pixelation: .01,
      current: this.props.current - 1
    }
  }

  componentDidMount() {
    this.events = this.props.events
    this.imageUrls = []

    for (var x = 0; x < this.events.length; x++) {
      this.imageUrls.push(this.events[x].image)
    }

    this.createCanvas()
  }

  componentWillUnmount() {
    this.stopRaf()
  }

  componentDidUpdate() {
    if (!this.props.pixelated && !this.imageVars.initialised && !this.imageVars.imagesLoaded) {
      this.imageLoadCount = 0
      this.loadImages()
      this.imageVars.imagesLoaded = true
      this.imageVars.initialised = true
      return
    }

    if (!this.props.pixelated && !this.imageVars.initialised) {
      this.imageVars.initialised = true
      setTimeout(this.animateIn, 700)
      return
    }

    if (this.props.pixelated && this.imageVars.initialised) {
      this.imageVars.initialised = false
      this.animateOut()
      return
    }

    if (this.props.current - 1 !== this.imageVars.current) {
      this.animateInOut()
      return
    }
  }

  animateOut = () => {
    this.ensurePlaying()
    TweenMax.to(this.imageVars, .6, {ease: Power3.easeOut, pixelation: .01, onComplete: this.stopRaf})
  }

  animateInOut = () => {
    this.ensurePlaying()
    TweenMax.to(this.imageVars, .6, {ease: Power3.easeOut, pixelation: .01, onComplete: this.animateIn})
  }

  animateIn = () => {
    this.ensurePlaying()
    this.imageVars.current = this.props.current - 1
    TweenMax.to(this.imageVars, .6, {ease: Power3.easeIn, pixelation: .9, onComplete: this.skipToNonPixelation})
  }

  skipToNonPixelation = () => {
    this.imageVars.pixelation = 1
    setTimeout(this.stopRaf, 10)
  }

  createCanvas = () => {
    this.canvas = document.createElement('canvas')

    this.canvas.width = this.cSize.w * 2
    this.canvas.height = this.cSize.h * 2
    this.canvas.style.width = this.cSize.w + "px"
    this.canvas.style.height = this.cSize.h + "px"

    this.ctx = this.canvas.getContext('2d')
    this.ctx.scale(2, 2)

    // Turn off smoothing
    this.ctx.mozImageSmoothingEnabled = false
    this.ctx.webkitImageSmoothingEnabled = false
    this.ctx.imageSmoothingEnabled = false

    this.mount.appendChild(this.canvas)
  }

  loadImages = () => {
    if (this.imageLoadCount === this.imageUrls.length) {
      this.drawImageOnCanvas()
      setTimeout(this.animateIn, 700)
      return
    }

    let img = new Image()

    img.crossOrigin = ''
    img.src = this.imageUrls[this.imageLoadCount]

    this.images.push(img)

    img.onload = () => {
      this.imageLoadCount++
      this.loadImages()
    }
  }

  ensurePlaying = () => {
    raf.cancel(this.raf)
    this.playing = true
    this.raf = raf(this.animate)
  }

  stopRaf = () => {
    this.playing = false
    raf.cancel(this.raf)
  }

  animate = () => {
    if (!this.playing) return

    this.drawImageOnCanvas()
    this.raf = raf(this.animate)
  }

  drawImageOnCanvas = () => {
    const size = this.imageVars.pixelation,
          w = this.cSize.w * size,
          h = this.cSize.h * size

    this.ctx.clearRect(0, 0, this.cSize.w, this.cSize.h)

    // Draw the image at the reduced size
    this.ctx.drawImage(this.images[this.imageVars.current], 0, 0, w, h)

    // Draw the canvas back on to itself at full size
    this.ctx.drawImage(this.canvas, 0, 0, Math.round(w * 2), Math.round(h * 2), 0, 0, this.cSize.w, this.cSize.h)
  }

  render() {

    return (
      <div
        className="timeline-canvas"
        ref={mount => (this.mount = mount)}
      />
    )
  }
}

export default TimelineImages