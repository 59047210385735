import React from "react"
import { InView } from 'react-intersection-observer'
import { TweenLite, Power0 } from "gsap"
import { MorphSVGPlugin } from "../../resources/scripts/MorphSVGPlugin"
import { isMobile } from 'react-device-detect'
import "./TimelineCarousel.scss"
import raf from 'raf'

// Components
import TimelineCard from "../TimelineCard/TimelineCard"
import TimelineDate from "../TimelineDate/TimelineDate"

// Images
import Image1 from "./images/1-Underseacable-1858.jpg"
import Image2 from "./images/2-Sputnik-1957.jpg"
import Image3 from "./images/3-ARPANET-1969.jpg"
import Image4 from "./images/4-Firstemail-1971.jpg"
import Image5 from "./images/5-kahncerf-1974.jpg"
import Image6 from "./images/6-spamemail-1978.jpg"
import Image7 from "./images/7-minitel-1981.jpg"
import Image8 from "./images/8-emoticon-1982.jpg"
import Image9 from "./images/9-timbernerslee-1989.jpg"
import Image10 from "./images/10-first-image-1992.jpg"
import Image11 from "./images/11-ClintonBildt-feb1994.jpg"
import Image12 from "./images/12-amazon-logo-july1994.jpg"
import Image13 from "./images/13-match-april1995.jpg"
import Image14 from "./images/14-ebaylogo-september1995.jpg"
import Image15 from "./images/15-JohnPerry-1996.jpg"
import Image16 from "./images/16-chinawall-1997.jpg"
import Image17 from "./images/17-google--september1998.jpg"
import Image18 from "./images/18-Alibaba-april1999.jpg"
import Image19 from "./images/19-Y2K-december1999-option2.jpg"
import Image20 from "./images/20-petsdotcom-march2000.jpg"
import Image21 from "./images/21-metallica-april2000.jpg"
import Image22 from "./images/22-skype-logo-2003.jpg"
import Image23 from "./images/22-wikipedia-january2001.jpg"
import Image24 from "./images/23-facebook-feb2004.jpg"
import Image25 from "./images/24-googlemaps-feb2005.jpg"
import Image26 from "./images/25-billionusers-dec2005.jpg"
import Image27 from "./images/26-rickroll-may2007.jpg"
import Image28 from "./images/27-iphone-june-2007-option1.jpg"
import Image29 from "./images/28-hashtag-august2007.jpg"
import Image30 from "./images/29-Internetaddict-november2007.jpg"
import Image31 from "./images/30-bitcoin-jan2009.jpg"
import Image32 from "./images/31-china-june2009.jpg"
import Image33 from "./images/32-flatearth-october2009.jpg"
import Image34 from "./images/33-arabspring-dec2010.jpg"
import Image35 from "./images/33-deathstar-dec2012.jpg"
import Image36 from "./images/35-msnlogo-april2013.jpg"
import Image37 from "./images/36-edwardsnowden-june2013.jpg"
import Image38 from "./images/36-U2-sept2014-option1.jpg"
import Image39 from "./images/37-thedress-feb2015.jpg"
import Image40 from "./images/38-Denmark-jan2017.jpg"
import Image41 from "./images/39-techbiollionare-march2017.jpg"
import Image42 from "./images/40-covfefe-may2017.jpg"
import Image43 from "./images/41-wannacry-may2017.jpg"
import Image44 from "./images/42-Cambridgeanalytic-march2018-option1.jpg"
import Image45 from "./images/43-GDPR-may2018-option1.jpg"
import Image46 from "./images/43-Yannyvlaurel-may2018.jpg"
import Image47 from "./images/44-halfworld-dec2018.jpg"
import Image48 from "./images/45-instagramegg-jan2019.jpg"
import Image49 from "./images/46-google-april2019.jpg"
import Image51 from "./images/46-pewdiepie-jan2019.jpg"
import Image52 from "./images/46-Vaccine-feb2019.jpg"
import Image53 from "./images/47-deepfake-april2020.jpg"
import Image54 from "./images/48-quantum-july2024.jpg"
import Image55 from "./images/49-surveillance-nov2024.jpg"
import Image56 from "./images/50-electricityuse-august2025.jpg"
import Image57 from "./images/51-satellite-feb2027.jpg"
import Image58 from "./images/52-Sweden-december2028.jpg"
import Image59 from "./images/53-splinternet-feb2030.jpg"
import Image60 from "./images/54-VR-april2032.jpg"
import Image61 from "./images/55-AI-september2034.jpg"
import Image62 from "./images/56-filedownload-october2037.jpg"
import Image63 from "./images/57-lithium-march2039.jpg"
import Image64 from "./images/58-historian-may2045.jpg"
import Image65 from "./images/59-brain-jan2053.jpg"
import Image66 from "./images/60-savebutton-november2097.jpg"
import Image67 from "./images/61-plug-december2100.jpg"

// eslint-disable-next-line
const plugin = MorphSVGPlugin; //just to keep tree shaking from dumping it.

const dates = [
  {
    year: 1858,
    events: [
      {
        month: "AUG",
        title: "First undersea cable successfully laid across the Atlantic",
        image: Image1
      }
    ]
  },
  {
    year: 1957,
    events: [
      {
        month: "OCT",
        title: "Sputnik launched, heralding in the era of satellite communications",
        image: Image2
      }
    ]
  },
  {
    year: 1969,
    events: [
      {
        month: "OCT",
        title: "ARPANET, the network that formed the basis for the internet, launched",
        image: Image3
      }
    ]
  },
  {
    year: 1971,
    events: [
      {
        month: "",
        title: "The first email is sent",
        image: Image4
      }
    ]
  },
  {
    year: 1974,
    events: [
      {
        month: "DEC",
        title: "Bob Kahn and Vint Cerf develop the pivotal TCP/IP protocols",
        image: Image5
      }
    ]
  },
  {
    year: 1978,
    events: [
      {
        month: "MAY",
        title: "The first spam email is sent",
        image: Image6
      }
    ]
  },
  {
    year: 1981,
    events: [
      {
        month: "MAY",
        title: "Most significant pre-WWW internet service Minitel launches in France",
        image: Image7
      }
    ]
  },
  {
    year: 1982,
    events: [
      {
        month: "SEP",
        title: "First use of an emoticon :-)",
        image: Image8
      }
    ]
  },
  {
    year: 1989,
    events: [
      {
        month: "MAR",
        title: "Sir Tim Berners-Lee first proposes the World Wide Web",
        image: Image9
      }
    ]
  },
  {
    year: 1992,
    events: [
      {
        month: "MAR",
        title: "First ever image uploaded onto the web",
        image: Image10
      }
    ]
  },
  {
    year: 1994,
    events: [
      {
        month: "FEB",
        title: "Bill Clinton and Swedish PM Carl Bildt first leaders to exchange emails",
        image: Image11
      },
      {
        month: "JUL",
        title: "Amazon founded by Jeff Bezos",
        image: Image12
      }
    ]
  },
  {
    year: 1995,
    events: [
      {
        month: "APR",
        title: "Match.com first online internet dating website goes online",
        image: Image13
      },
      {
        month: "SEP",
        title: "First Ebay transaction (a broken laser pointer!) completed",
        image: Image14
      }
    ]
  },
  {
    year: 1996,
    events: [
      {
        month: "FEB",
        title: "Internet pioneer and Grateful Dead songwriter pens Declaration of the Independence of Cyberspace",
        image: Image15
      }
    ]
  },
  {
    year: 1997,
    events: [
      {
        month: "MAY",
        title: "Beijing passes first internet censorship legislation",
        image: Image16
      }
    ]
  },
  {
    year: 1998,
    events: [
      {
        month: "SEP",
        title: "Sergey Brin and Larry Page found Google",
        image: Image17
      }
    ]
  },
  {
    year: 1999,
    events: [
      {
        month: "APR",
        title: "Jack Ma founds Alibaba.com, China’s largest e-commerce platform",
        image: Image18
      },
      {
        month: "DEC",
        title: "Global internet community successfully navigates the Y2K Millennium Bug",
        image: Image19
      },
    ]
  },
  {
    year: 2000,
    events: [
      {
        month: "MAR",
        title: "The dot-com bubble bursts. Stock value drops $5 trillion in the next 18 months",
        image: Image20
      },
      {
        month: "APR",
        title: "Metallica sues file-sharing service Napster over copyright infringement",
        image: Image21
      },
    ]
  },
  {
    year: 2001,
    events: [
      {
        month: "JAN",
        title: "Wikipedia founded by Jimmy Wales",
        image: Image23
      }
    ]
  },
  {
    year: 2003,
    events: [
      {
        month: "",
        title: "First Skype call",
        image: Image22
      }
    ]
  },
  {
    year: 2004,
    events: [
      {
        month: "FEB",
        title: "Mark Zuckerberg launches “the Facebook” on Harvard campus",
        image: Image24
      }
    ]
  },
  {
    year: 2005,
    events: [
      {
        month: "FEB",
        title: "Google Map launches",
        image: Image25
      },
      {
        month: "DEC",
        title: "The internet reaches one billion users for the first time",
        image: Image26
      }
    ]
  },
  {
    year: 2007,
    events: [
      {
        month: "MAY",
        title: `<a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer">1987 music video goes viral.</a>`,
        image: Image27
      },
      {
        month: "JUN",
        title: "The iPhone is launched",
        image: Image28
      },
      {
        month: "AUG",
        title: "First use of a hashtag",
        image: Image29
      },
      {
        month: "NOV",
        title: "First bootcamp for internet-addicted teenagers opens in South Korea",
        image: Image30
      }
    ]
  },
  {
    year: 2009,
    events: [
      {
        month: "JAN",
        title: "The enigmatic Satoshi Nakamoto publishes bitcoin whitepaper",
        image: Image31
      },
      {
        month: "JUN",
        title: "More Chinese than American internet users",
        image: Image32
      },
      {
        month: "OCT",
        title: "The Flat Earth Society relaunches online",
        image: Image33
      }
    ]
  },
  {
    year: 2010,
    events: [
      {
        month: "DEC",
        title: "“The Revolution will be live-streamed”- start of the Arab Spring",
        image: Image34
      }
    ]
  },
  {
    year: 2012,
    events: [
      {
        month: "DEC",
        title: "Death Star e-petition warrants White House response",
        image: Image35
      }
    ]
  },
  {
    year: 2013,
    events: [
      {
        month: "APR",
        title: "Death of MSN Messenger",
        image: Image36
      },
      {
        month: "JUN",
        title: "Edward Snowden reveals extent of government surveillance",
        image: Image37
      }
    ]
  },
  {
    year: 2014,
    events: [
      {
        month: "SEP",
        title: "Backlash over the hard-to-delete U2 album included in iPhone software update",
        image: Image38
      }
    ]
  },
  {
    year: 2015,
    events: [
      {
        month: "FEB",
        title: "The dress (is it black/blue, is it white/gold?) takes the internet by storm",
        image: Image39
      }
    ]
  },
  {
    year: 2017,
    events: [
      {
        month: "JAN",
        title: "Denmark appoints world’s first digital ambassador to Silicon Valley",
        image: Image40
      },
      {
        month: "MAR",
        title: "Tech billionaires worth more than 1 trillion dollars",
        image: Image41
      },
      {
        month: "MAY",
        title: "President Trump asks for covfefe on Twitter",
        image: Image42
      },
      {
        month: "MAY",
        title: "WannaCry cyberattack cripples the UK’s NHS and others worldwide",
        image: Image43
      },
    ]
  },
  {
    year: 2018,
    events: [
      {
        month: "MAR",
        title: "Carole Cadwalladr exposes Cambridge Analytica’s global election meddling",
        image: Image44
      },
      {
        month: "MAY",
        title: "GDPR is implemented",
        image: Image45
      },
      {
        month: "MAY",
        title: "Yanny vs. Laurel 2018 version of ‘the dress’ unifies and divides",
        image: Image46
      },
      {
        month: "DEC",
        title: "Half of the world’s population now online",
        image: Image47
      },
    ]
  },
  {
    year: 2019,
    events: [
      {
        month: "JAN",
        title: "Egg breaks record of most-liked Instagram post of all time",
        image: Image48
      },
      {
        month: "JAN",
        title: "Pewdiepie reaches 80 million followers on Youtube",
        image: Image51
      },
      {
        month: "FEB",
        title: "Pinterest bans anti-vaxx content, other platforms follow suit",
        image: Image52
      },
      {
        month: "APR",
        title: "Google cancels AI ethics board after one week following public outcry",
        image: Image49
      }
    ]
  },
  {
    year: 2020,
    events: [
      {
        month: "APR",
        title: "Deepfake sparks geopolitical incident",
        image: Image53
      }
    ]
  },
  {
    year: 2024,
    events: [
      {
        month: "JUL",
        title: "Quantum breaks encryption as we know it",
        image: Image54
      },
      {
        month: "NOV",
        title: "New regulation and companies kill surveillance capitalist business model",
        image: Image55
      }
    ]
  },
  {
    year: 2025,
    events: [
      {
        month: "AUG",
        title: "The internet now consumes one-fifth of global electricity",
        image: Image56
      }
    ]
  },
  {
    year: 2027,
    events: [
      {
        month: "FEB",
        title: "New satellite systems give everyone worldwide reliable internet access",
        image: Image57
      }
    ]
  },
  {
    year: 2028,
    events: [
      {
        month: "DEC",
        title: "Chaos in Sweden as blackout brings cashless society to a halt",
        image: Image58
      }
    ]
  },
  {
    year: 2030,
    events: [
      {
        month: "FEB",
        title: "Internet splits up in balkanised pieces, becoming the splinternet",
        image: Image59
      }
    ]
  },
  {
    year: 2032,
    events: [
      {
        month: "APR",
        title: "VR allows everyone visit the long-lost seven wonders of the world",
        image: Image60
      }
    ]
  },
  {
    year: 2034,
    events: [
      {
        month: "APR",
        title: "First AI gets elected into public office",
        image: Image61
      }
    ]
  },
  {
    year: 2037,
    events: [
      {
        month: "OCT",
        title: "Your video file from 1999 finally finishes downloading",
        image: Image62
      }
    ]
  },
  {
    year: 2039,
    events: [
      {
        month: "MAR",
        title: "We run out of lithium, and can’t make new smartphones",
        image: Image63
      }
    ]
  },
  {
    year: 2045,
    events: [
      {
        month: "MAY",
        title: "Exasperated historian tries to find primary internet sources",
        image: Image64
      }
    ]
  },
  {
    year: 2053,
    events: [
      {
        month: "JAN",
        title: "First successful Wikipedia brain implant",
        image: Image65
      }
    ]
  },
  {
    year: 2097,
    events: [
      {
        month: "NOV",
        title: "Last person who knows the “save button” signifies a floppy disk, dies",
        image: Image66
      }
    ]
  },
  {
    year: 2100,
    events: [
      {
        month: "DEC",
        title: "We pull the plug",
        image: Image67
      }
    ]
  }
]

class TimelineCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      init: false,
      entryComplete: false,
      current: 34,
      dragging: false,
      playing: false,
      pixelated: true
    })

    // this.dates = []
    this.circles = []
    // this.sounds = []

    this.timelines = []
    this.progressionCurrent = []
    this.progressionAim = []

    this.progressionPoints = [
      0,
      0.18,
      0.27,
      0.35,
      0.5,
      0.66,
      0.74,
      0.83,
      1
    ]

    this.closestAnchor = 34
    this.dragProgress = 0
    this.prevDragProgress = 0
    this.dragCushion = 0.02
  }

  componentDidMount() {
    this.createTimelines()
    this.maxDragProgress = (dates.length - 1) * 0.1 + this.dragCushion
  }

  componentWillUnmount() {
    this.stopRaf()
  }

  createTimelines = () => {
    this.maxCurrent = dates.length + this.progressionPoints.length - 10

    for (var x = 0; x < dates.length; x++) {
      let tween,
          motionPath = MorphSVGPlugin.pathDataToBezier(this.path, {align: this.circles[x]}),
          yPos = (x % 2 ? -15 : -80)

      TweenLite.set(this.circles[x], {xPercent: -50, yPercent: yPos})

      tween = TweenLite.to(this.circles[x], 1, {ease: Power0.easeNone, bezier: {values: motionPath, type: "cubic"}, onUpdate: this.updateSlider, paused: true})

      this.timelines.push(tween)
      this.progressionCurrent.push(0)
      this.progressionAim.push(0)
    }

    this.raf = raf(this.animate)
  }

  timelineInView = (inView, entry) => {
    if (this.state.init || !inView) return

    this.setState({
      init: true,
      current: 34
    })

    this.showFirstCardTimeout = setTimeout(this.showFirstCard, 100)
    this.setInitPos()
  }

  setInitPos = () => {
    for (var x = 0; x < this.timelines.length; x++) {
      let currentPoint = (this.state.current - x < 0 ? 0 : this.state.current - x)
      currentPoint = (currentPoint > this.progressionPoints.length - 1 ? this.progressionPoints.length - 1 : currentPoint)

      this.progressionCurrent[x] = this.progressionPoints[currentPoint]
      this.timelines[x].progress(this.progressionCurrent[x])
    }

    this.closestAnchor = this.state.current
    this.dragProgress = (this.state.current - 4) * 0.1
    this.prevDragProgress = this.dragProgress
  }

  prevDate = () => {
    this.stopAudio()
    this.ensurePlaying()
    this.updatePrevDrag()

    this.setState({
      current: (this.state.current <= 4 ? this.maxCurrent + 4 : this.state.current - 1)
    }, () => {
      this.clickNavigationEnded()
    })
  }

  nextDate = () => {
    this.stopAudio()
    this.ensurePlaying()
    this.updatePrevDrag()

    this.setState({
      current: (this.state.current >= this.maxCurrent + 4 ? 4 : this.state.current + 1)
    }, () => {
      this.clickNavigationEnded()
    })
  }

  handleMouseDown = (e) => {
    this.ensurePlaying()
    this.startDrag()
    this.dragStart = e.clientX
  }

  handleTouchStart = (e) => {
    this.ensurePlaying()
    this.startDrag()
    this.dragStart = e.touches[0].clientX
  }

  startDrag = () => {
    this.stopAudio()

    this.setState({
      dragging: true,
      pixelated: true
    })
  }

  handleMouseMove = (e) => {
    if (!this.state.dragging) return

    let distancePx = e.clientX - this.dragStart
    this.setDrag(distancePx)
  }

  handleTouchMove = (e) => {
    if (!this.state.dragging) return

    let distancePx = e.touches[0].clientX - this.dragStart
    this.setDrag(distancePx)
  }

  setDrag = (distancePx) => {
    if (distancePx === 0) {
      return
    }

    let distance = (distancePx / -10000)

    this.dragProgress = (distance * this.maxDragProgress) + this.prevDragProgress
    this.dragProgress = (this.dragProgress < -this.dragCushion ? -this.dragCushion : this.dragProgress)
    this.dragProgress = (this.dragProgress > this.maxDragProgress ? this.maxDragProgress : this.dragProgress)

    let dragPercentage = this.dragProgress / this.maxDragProgress

    this.closestAnchor = Math.ceil((dragPercentage * this.maxCurrent) - 0.5) + 4
  }

  handleMouseUp = () => {
    this.stopDrag()
  }

  handleMouseLeave = () => {
    // Only track this event if mid drag
    if (!this.state.dragging) return

    this.stopDrag()
  }

  handleTouchEnd = () => {
    this.stopDrag()
  }

  stopDrag = () => {

    this.setState({
      entryComplete: true,
      current: this.closestAnchor,
      dragging: false,
      pixelated: false
    },
      () => {
        this.playAudio()
      }
    )

    this.prevDragProgress = this.dragProgress
    this.countdownToStop()
  }

  updatePrevDrag = () => {
    this.dragProgress = (this.state.current - 3) * 0.1
    this.prevDragProgress = this.dragProgress
  }

  ensurePlaying = () => {
    clearTimeout(this.stopRafTimeout)
    if (!this.state.playing) this.startRaf()
  }

  startRaf = () => {
    this.setState({
      playing: true
    })

    this.raf = raf(this.animate)
  }

  stopRaf = () => {
    clearTimeout(this.stopRafTimeout)
    clearTimeout(this.showFirstCardTimeout)
    this.setState({
      playing: false
    })
    raf.cancel(this.raf)
  }

  countdownToStop = () => {
    this.stopRafTimeout = setTimeout(this.stopRaf, 10000)
  }

  clickNavigationEnded = () => {
    this.closestAnchor = this.state.current
    this.dragProgress = (this.state.current - 4) * 0.1
    this.prevDragProgress = this.dragProgress

    this.setState({
      entryComplete: true,
      current: this.closestAnchor,
      dragging: false,
      pixelated: false
    }, () => {
      this.countdownToStop()
      this.playAudio()
    })
  }

  showFirstCard = () => {
    this.setState({
      entryComplete: true,
      pixelated: false
    }, () => {
      // this.playAudio()
    })
  }

  playAudio = () => {
    if (isMobile) return

    // let audio = this.sounds[this.state.current - 4],
    //     canPlay = audio.readyState

    // if (canPlay < 4) return
    // audio.play()
  }

  stopAudio = () => {
    // let audio = this.sounds[this.state.current - 4]

    // audio.currentTime = 0
    // audio.pause()
  }

  animate = () => {
    if (!this.state.playing) return

    for (var x = 0; x < this.timelines.length; x++) {
      if (this.state.dragging) {
        let newProgress = (this.dragProgress + 0.5) - (0.1 * x)
        newProgress = (newProgress < 0 ? 0 : newProgress)
        newProgress = (newProgress > 1 ? 1 : newProgress)

        this.progressionCurrent[x] += (newProgress - this.progressionCurrent[x]) * 0.05
      } else {
        let currentPoint = (this.state.current - x < 0 ? 0 : this.state.current - x)
        currentPoint = (currentPoint > this.progressionPoints.length - 1 ? this.progressionPoints.length - 1 : currentPoint)

        this.progressionCurrent[x] += (this.progressionPoints[currentPoint] - this.progressionCurrent[x]) * 0.05
        this.progressionCurrent[x] = Math.ceil(this.progressionCurrent[x] * 100000) / 100000
      }

      this.timelines[x].progress(this.progressionCurrent[x])
    }

    this.raf = raf(this.animate)
  }

  render() {

    return (
      <InView
        onChange={this.timelineInView}
        threshold={[.5]}
        triggerOnce={true}
      >
        {({ inView, ref }) => (
          <>
            <div className="timeline__cards" data-visible={!this.state.dragging}>
              {dates.map((node, index) => {
                const stack = (index % 2 ? "bottom" : "top"),
                      visibility = (index === this.state.current - 4 && !this.state.dragging && this.state.entryComplete ? true : false),
                      pixelated = (this.state.pixelated || !visibility ? true : false)

                return (
                  <TimelineCard
                    key={index}
                    stack={stack}
                    visibility={visibility}
                    pixelated={pixelated}
                    prevDate={this.prevDate}
                    nextDate={this.nextDate}
                    {...node}
                  />
                )
              })}
            </div>
            <div
              className="timeline__carousel"
              ref={ref}
              data-anim={inView}
              onMouseDown={this.handleMouseDown}
              onMouseMove={this.handleMouseMove}
              onMouseUp={this.handleMouseUp}
              onMouseLeave={this.handleMouseLeave}

              onTouchStart={this.handleTouchStart}
              onTouchMove={this.handleTouchMove}
              onTouchEnd={this.handleTouchEnd}
            >
              <div className="timeline__carousel-line">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4203.56 360" preserveAspectRatio="none">
                  <path d="M4203.56 80H3802c-176.88 0-176.88 200-353.76 200s-158.16-180-335-180-145 134-321.92 134c-162 0-152.63-84-292-84-120 0-120.71 53-297.59 53-41.53 0-73.3.17-99.91 0h-.12c-26.61.17-58.39 0-99.91 0-176.88 0-177.58-53-297.59-53-139.34 0-130 84-292 84-176.88 0-145-134-321.92-134s-158.16 180-335 180S578.41 80 401.53 80H0" fill="none" stroke="transparent" strokeMiterlimit="10" strokeWidth="2" ref={path => (this.path = path)} />

                  <path className="timeline__path timeline__path--past" d="M2101.82,203h-.12c-26.61.17-58.39,0-99.91,0-176.88,0-177.58-53-297.59-53-139.34,0-130,84-292,84-176.88,0-145-134-321.92-134s-158.16,180-335,180S578.41,80,401.53,80H0"  fill="none" stroke="#fb998e" strokeMiterlimit="10" strokeWidth="2" />

                  <path className="timeline__path timeline__path--future" d="M2101.7,203h.12c26.61.17,58.38,0,99.91,0,176.88,0,177.59-53,297.59-53,139.37,0,130,84,292,84,176.92,0,145.08-134,321.92-134s158.12,180,335,180S3625.12,80,3802,80h401.56"  fill="none" stroke="#fb998e" strokeMiterlimit="10" strokeWidth="2" />

                  {dates.map((node, index) => {
                    const year = node.year,
                          yPos = (index % 2 ? 128 : 48),
                          stepsFromCurrent = Math.abs(this.state.current - 4 - index)

                    return (
                      <g className="timeline__circle" data-steps={stepsFromCurrent} key={index} ref={circle => (this.circles[index] = circle)}>
                        <TimelineDate yPos={yPos} year={year} />
                      </g>
                    )
                  })}
                </svg>
              </div>
            </div>
            <div className="timeline__audio">
              {/*dates.map((node, index) => {

                return (
                  <audio
                    key={index}
                    src="./audio/dial-up.ogg"
                    ref={sound => (this.sounds[index] = sound)}
                    autoPlay={false}
                    loop={true}
                  />
                )
              })}*/}
            </div>
          </>
        )}
      </InView>
    )
  }
}

export default TimelineCarousel