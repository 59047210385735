import React from "react"
import { InView } from 'react-intersection-observer'
import "./Credits.scss"

class Credits extends React.Component {

  render() {

    return (
      <InView
        threshold={.35}
        triggerOnce={true}
      >
        {({ inView, ref }) => (
          <div className="credits" ref={ref} data-anim={inView}>
            <div className="container">
              <div className="cols">
                <div className="credits__col col m12 t6 d4">
                  <div className="credits__creditations">
                    <h5>Credits:</h5>
                    <p>Introductions and chapter heading text by Katja Bego.<br /> This book was curated and edited by Caroline Back, Katja Bego and Amelia Tait. Interviews conducted and edited by Amelia Tait.</p>
                    <p>Website design and development by <a href="https://toyfight.co" target="_blank" rel="noopener noreferrer">ToyFight</a>.</p>
                  </div>
                </div>
                <div className="credits__col col m12 t6 d5">
                  <div className="credits__acknowledgements">
                    <h5>Acknowledgments:</h5>
                    <p>This project was created as part of the <a href="https://www.ngi.eu/" target="_blank" rel="noopener noreferrer">Next Generation Internet</a> initiative funded under the EC H2020 project EU Engineroom, Grant Agreement 780643.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </InView>
    )
  }
}

export default Credits