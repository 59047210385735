import React from "react"
import "./Chapter.scss"

// Components
import ChapterHeader from "../ChapterHeader/ChapterHeader"
import ChapterIntro from "../ChapterIntro/ChapterIntro"
import StoryList from "../StoryList/StoryList"

class Chapter extends React.Component {

  render() {

    return (
      <>
        <ChapterHeader {...this.props} />
        <ChapterIntro {...this.props} />
        <StoryList {...this.props} />
      </>
    )
  }
}

export default Chapter