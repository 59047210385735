import React from "react"
import { InView } from 'react-intersection-observer'
import "./ChapterIntro.scss"

// Components
import ChapterIntroImage from "../ChapterIntroImage/ChapterIntroImage"
import ChapterIntroText from "../ChapterIntroText/ChapterIntroText"

// Icons
import iconSvgs from "../Chapter/ChapterIcons"

class ChapterIntro extends React.Component {

  render() {
    const {heading} = this.props,
          headingLower = heading.toLowerCase(),
          {title} = this.props,
          svg = iconSvgs[headingLower],
          {pageWipe} = this.props,
          {introImage} = this.props,
          {introImageMobile} = this.props,
          {access} = this.props

    return (
      <InView
        threshold={[.2]}
        triggerOnce={true}
      >
        {({ inView, ref }) => (
          <div className="chapter-intro" data-anim={inView} ref={ref}>
            <div className="chapter-intro__container container">

              <div className="cols flex">

                <div className="chapter-intro__col chapter-intro__col--content col m12 t7">
                  {access && (
                    <div className="chapter-intro__access-heading">
                      <h2 tabIndex="0">{heading}</h2>
                      <h4 dangerouslySetInnerHTML={{__html: title}} tabIndex="0" />
                    </div>
                  )}
                  <div className="chapter-intro__title">
                    <h4>
                      <span>
                        <span>{heading}</span>
                      </span>
                      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 40">
                        <path d="M1,1 1,39 89,39 89,1 1,1" strokeWidth="1" stroke="#1125aa" fill="none" />
                      </svg>
                    </h4>
                    <div className="chapter-intro__icon">
                      <div className={`chapter-intro__icon-svg chapter-intro__icon-svg--${headingLower}`} dangerouslySetInnerHTML={{__html: svg}} />
                      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                        <path d="M1,41 41,41 41,1 1,1" strokeWidth="1" stroke="#1125aa" fill="none" />
                      </svg>
                    </div>
                    <h4>
                      <span>
                        <span dangerouslySetInnerHTML={{__html: title}} />
                        <span dangerouslySetInnerHTML={{__html: title}} />
                      </span>
                      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 50">
                        <path d="M1,1 339,1 339,49 1,49 1,1" strokeWidth="1" stroke="#1125aa" fill="none" />
                      </svg>
                    </h4>
                  </div>

                  <div className="chapter-intro__content" data-anim={inView} ref={ref}>
                    <ChapterIntroText heading={heading} pageWipe={pageWipe} access={access} />
                  </div>
                </div>

                {introImage && introImageMobile && !access && (
                  <ChapterIntroImage url={introImage.publicURL} urlMobile={introImageMobile.publicURL} width="532" height="702" />
                )}

              </div>

            </div>
          </div>
        )}
      </InView>
    )
  }
}

export default ChapterIntro