import React from "react"
import "./TimelineCard.scss"

// Component
import TimelineImages from "../TimelineImages/TimelineImages"

// Images
import Arrow from "./images/arrow.svg"

class TimelineCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      current: 1
    })
  }

  prevEvent = () => {
    if (this.state.current === 1) {
      this.props.prevDate()
      return
    }

    let newCurrent = this.state.current - 1

    this.setState({
      current: newCurrent
    })
  }

  nextEvent = () => {
    if (this.state.current === this.props.events.length) {
      this.props.nextDate()
      return
    }

    let newCurrent = this.state.current + 1

    this.setState({
      current: newCurrent
    })
  }

  paginationClick = (event) => {
    const {id} = event.target

    if (this.state.current === id) return

    this.setState({
      current: id
    })
  }

  render() {
    const {visibility} = this.props,
          {year} = this.props,
          {events} = this.props

    let {pixelated} = this.props

    return (
      <div className="timeline__card" data-visible={visibility} data-current={this.state.current}>
        <div className="timeline__card-inner">
          <div className="timeline__card-header">
            {/* events.length > 1 &&
              <div className="timeline__card-arrow timeline__card-arrow--prev" onClick={this.prevEvent}>
                <img src={Arrow} alt="Previous" />
              </div>
            */}
            <div className="timeline__card-arrow timeline__card-arrow--prev" onClick={this.prevEvent}>
              <img src={Arrow} alt="Previous" />
            </div>
            <div className="h5">
              <div className="timeline__card-months">
                {events.map((node, index) => {
                  const month = node.month

                  return (
                    <span key={index} dangerouslySetInnerHTML={{ __html: month }} />
                  )
                })}
              </div>
              <span className="timeline__card-year">{year}</span>
            </div>
            {/* events.length > 1 &&
              <div className="timeline__card-arrow timeline__card-arrow--next" onClick={this.nextEvent}>
                <img src={Arrow} alt="Next" />
              </div>
            */}
            <div className="timeline__card-arrow timeline__card-arrow--next" onClick={this.nextEvent}>
              <img src={Arrow} alt="Next" />
            </div>
          </div>
          <div className="timeline__card-image">
            <TimelineImages events={events} current={this.state.current} pixelated={pixelated} />
          </div>
          <div className="timeline__card-bottom">
            <div className="timeline__card-title">
              {events.map((node, index) => {
                const title = node.title

                return (
                  <p key={index} dangerouslySetInnerHTML={{ __html: title }} />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TimelineCard