import React from "react"
import { InView } from 'react-intersection-observer'
import "./Timeline.scss"

// Components
import TimelineCarousel from "../TimelineCarousel/TimelineCarousel"

class Timeline extends React.Component {

  render() {

    return (
      <InView
        threshold={[.4]}
        triggerOnce={true}
      >
        {({ inView, ref }) => (
          <div className="timeline" id="chapter-timeline" data-anim={inView} ref={ref}>
            <div className="timeline__inner">
              <h2>Internet timeline</h2>
              <p>
                <span>This</span> <span>timeline</span> <span>shows</span> <span>some</span> <span>of</span> <span>the</span> <span>internet's</span> <span>most</span> <span>important</span> <span>milestones</span> <span>s</span><span>o</span> <span>f</span><span>a</span><span>r</span><span>,</span> <span>and</span> <span>speculates</span> <span>on</span> <span>where</span> <span>we</span> <span>might</span> <span>go</span> <span>n</span><span>e</span><span>x</span><span>t</span><span>…</span>
              </p>

              <TimelineCarousel />
            </div>
          </div>
        )}
      </InView>
    )
  }
}

export default Timeline