import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./ChapterPopup.scss"

// Components
import CustomLink from "../CustomLink/CustomLink"

// Images
import Arrow from "./images/arrow.svg"

class ChapterPopup extends React.Component {

  render() {
    let {visible} = this.props,
        {open} = this.props,
        homeChapter = this.props.context.data.homeChapter

    const {show} = this.props,
          {hide} = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark (
              filter: {
                frontmatter: {type: {eq: "chapter"}}
              },
              sort: {
                fields: [frontmatter___order]
                order: ASC
              }
            ) {
              edges {
                node {
                  excerpt
                  fields {
                    slug
                  }
                  frontmatter {
                    order
                    heading
                    title
                    type
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="chapter-list-positioner container">
            <div className="chapter-list__wrap">
              <div className="chapter-list" data-open={open}>
                <div className="chapter-list__inner">
                  <div className="chapter-list__header" onClick={open ? hide : show} data-visible={visible} aria-label={open ? "Close Menu" : "Open Menu"} title={open ? "Close Menu" : "Open Menu"}>
                    <h5>Index</h5>
                    <div className="chapter-list__btn-icon" onClick={open ? hide : show} />
                  </div>
                  <div className="chapter-list__content">
                    <div className="chapter-list__content-inner">
                      <ul>
                        <li data-current={homeChapter === 1 ? true : false}>
                          <img className="chapter-list__arrow" src={Arrow} width="6" height="1" alt="current" />
                          <CustomLink label="Foreword" modifier="h5 alt" target="chapter-foreword" anchor={true} clickEvent={hide} />
                          <CustomLink label="Radical new visions" modifier="chapter__title" target="chapter-foreword" anchor={true} clickEvent={hide} />
                        </li>
                        <li data-current={homeChapter === 2 ? true : false}>
                          <img className="chapter-list__arrow" src={Arrow} width="6" height="1" alt="current" />
                          <CustomLink label="Timeline" modifier="h5 alt" target="chapter-timeline" anchor={true} clickEvent={hide} />
                          <CustomLink label="The internet’s history and future" modifier="chapter__title" target="chapter-timeline" anchor={true} clickEvent={hide} />
                        </li>
                        {data.allMarkdownRemark.edges.map(({ node }, index) => {
                          const heading = node.frontmatter.heading || "",
                                title = node.frontmatter.title || node.fields.slug,
                                headingFormatted = heading.toLowerCase().replace(/ /g, '-')

                          let current = (index + 3 === homeChapter ? true : false)

                          return (
                            <li key={node.fields.slug} data-current={current}>
                              <img className="chapter-list__arrow" src={Arrow} width="6" height="1" alt="current" />
                              <CustomLink label={heading} modifier="h5 alt" target={`chapter-${headingFormatted}`} offset={-50} anchor={true} clickEvent={hide} />
                              <CustomLink label={title} modifier="chapter__title" target={`chapter-${headingFormatted}`} offset={-50} anchor={true} clickEvent={hide} />
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

export default ChapterPopup