import React from "react"
import { TweenMax, Power3 } from "gsap/TweenMax"

class IndexWipe extends React.Component {

  componentDidMount() {

    if (!this.props.context.data.homeInit) return
    TweenMax.fromTo(this.pageWipePanel, .75, {x: "0%"}, {ease: Power3.easeIn, x: "100%", delay: 1})

    this.scrollEnsure = setInterval(this.setScroll, 250)
  }

  setScroll = () => {
    if (window.pageYOffset > 0) {
      clearInterval(this.scrollEnsure)
      return
    }

    const pos = this.props.context.data.homeScroll
    window.scrollTo(0, pos)

    this.props.showBtn()
  }

  render() {

    return (
      <div className="page-wipe">
        <div className="page-wipe__panel page-wipe__panel--article" ref={pageWipe => (this.pageWipePanel = pageWipe)} />
      </div>
    )
  }
}

export default IndexWipe