import React from "react"
import Consumer from "../../layouts/Context"
import { InView } from 'react-intersection-observer'
import ScrollPercentage from 'react-scroll-percentage'
// import raf from 'raf'
// import _ from 'lodash'
import "./ChapterHeader.scss"

// Icons
import iconSvgs from "../Chapter/ChapterIcons"

class ChapterHeader extends React.Component {
  constructor(props) {
    super(props)

    this.formedShape = false
    this.animating = false
    this.isInView = false
    this.textOffset = -1
    this.headingOffset = 0
    // this.throttledSetYPos = _.throttle(this.setYPos, 10)
  }

  headerInView = (percentage, inView) => {
    // this.headingOffsetTarget = Math.round((5000 * percentage) - 2500) / 10

    // Calculate position based on percentage and round it to 3 decimal points
    // this.yPos = Math.round((-4000 * percentage) + 2000) / 1000
    // this.throttledSetYPos()

    if (inView && !this.formedShape) {
      // If the bottom of the header section has passed the
      // bottom of the window in up direction animate the shape in
      this.formedShape = true
      this.countdownTimer = setTimeout(this.formShape, 500)
    } else if (!inView && percentage === 1 && this.formedShape) {
      // If the top of the header section has passed the
      // top of the window animate the shape out
      this.unformShapeDown()
    } else if (!inView && percentage === 0 && this.formedShape) {
      // If the bottom of the header section has passed the
      // bottom of the window in down direction animate the shape out
      this.unformShapeUp()
    }
  }

  headerContentInView = (inView, entry) => {

    if (!inView && entry.boundingClientRect.y > 0 && this.isInView) {
      this.isInView = false
      // If the bottom of the header section has passed the
      // bottom of the window in down direction animate the shape out
      this.context.set({
        homeChapter: this.props.order + 1
      })
    } else if (inView && !this.isInView) {
      this.isInView = true
      this.context.set({
        homeChapter: this.props.order + 2
      })
    }
  }

  setYPos = () => {
    this.context.set({
      yPos: this.yPos
    })
  }

  // Set context state to the new shape
  formShape = () => {
    if (this.countdownTimer) clearTimeout(this.countdownTimer)

    this.context.set({
      shape: this.props.shape
    })
  }

  // Set formed flag to false
  unformShapeUp = () => {
    if (this.countdownTimer) clearTimeout(this.countdownTimer)
    this.formedShape = false
  }

  // Set formed flag to false
  unformShapeDown = () => {
    if (this.countdownTimer) clearTimeout(this.countdownTimer)
    this.formedShape = false
  }

  setTitlePos = () => {
    // this.headingOffset += (this.headingOffsetTarget - this.headingOffset) * .8
    // this.heading.style.transform = `translate3d(0, ${this.headingOffset}px, 0)`

    // this.animationFrame = raf(this.setTitlePos)
  }

  render() {
    const {heading} = this.props,
          headingLower = heading.toLowerCase(),
          headingChars = heading.split(""),
          {title} = this.props,
          svg = iconSvgs[headingLower],
          headingFormatted = heading.toLowerCase().replace(/ /g, '-')

    return (
      <ScrollPercentage
        className="chapter-header"
        id={`chapter-${headingFormatted}`}
        onChange={this.headerInView}
        threshold={[0]}
      >
        <div className="chapter-header__container container flex">
          <InView
            onChange={this.headerContentInView}
            threshold={[1]}
          >
            {({ inView, ref }) => (
              <div className="chapter-header__content-wrap">
                <div className="chapter-header__content" ref={ref} data-anim={inView}>
                  <div className="chapter-header__content-inner">
                    <div className="h1">
                      {
                        headingChars.map(({node}, index) => {
                          return (
                            <span key={index}>{headingChars[index]}</span>
                          )
                        })
                      }
                      <div className="chapter-header__icon">
                        <div className={`chapter-header__icon-svg chapter-header__icon-svg--${headingLower}`} dangerouslySetInnerHTML={{__html: svg}} />
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                          <path d="M1,1 41,1 41,41 1,41 1,1" strokeWidth="1" stroke="#ffffff" fill="none" />
                        </svg>
                      </div>
                    </div>
                    <h4 dangerouslySetInnerHTML={{__html: title}} />
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </ScrollPercentage>
    )
  }
}

ChapterHeader.contextType = Consumer

export default ChapterHeader