import React from "react"
import Consumer from "../../layouts/Context"
import "./ChapterList.scss"

// Components
import Chapter from "../Chapter/Chapter"

class ChapterList extends React.Component {

  render() {
    const {chapters} = this.props,
          {stories} = this.props,
          {updateMenu} = this.props,
          {pageWipe} = this.props,
          {access} = this.props

    return (
      <div className="home-chapters" id="chapters">
        {chapters.map(({node}) => {
          const props = node.frontmatter,
                content = node.html,
                chapter = props.order

          let linkedStories = []

          for (var i = 0; i < stories.length; i++) {
            let storyChapter = stories[i].node.frontmatter.chapter

            if (chapter === storyChapter) {
              linkedStories.push(stories[i])
            }
          }

          return (
            <div className="chapter" key={props.title}>
              <Chapter {...props} content={content} stories={linkedStories} update={updateMenu} pageWipe={pageWipe} access={access} />
            </div>
          )
        })}
      </div>
    )
  }
}

ChapterList.contextType = Consumer

export default ChapterList