import React from "react"
import Consumer from "../../layouts/Context"
import { TweenMax, Power3 } from "gsap/TweenMax"
import TransitionLink from 'gatsby-plugin-transition-link'

class ChapterIntroText extends React.Component {
  exitHome = (node, e, exit, entry) => {
    this.context.set({
      homeInit: true,
      homeScroll: window.pageYOffset
    })

    TweenMax.to(this.props.pageWipe, .75, {ease: Power3.easeOut, x: "0"})
  }

  exitHomeAccess = (node, e, exit, entry) => {
    console.log('GO ACCESS')
  }

  render() {
    const {heading} = this.props,
          {access} = this.props

    const linkPrefix = (access ? "/text/" : "/"),
          length = (access ? .2 : .75),
          triggerOut = (access ? ({node, e, exit, entry}) => this.exitHomeAccess(node, e, exit, entry) : ({node, e, exit, entry}) => this.exitHome(node, e, exit, entry))

    if (heading === "Enter") {
      return (
        <div>
          <p>“Vague, but exciting,” Mike Sendall scribbled in the margins of a proposal submitted by his employee Tim Berners-Lee on 12 March 1989. What came next is history: just a few years later, Berners-Lee’s self-described “hypertext thing” – designed to be used for information management within CERN – became the World Wide Web. Rapidly, the WWW grew from a niche academic network to a global audience (
            <TransitionLink
              to={`${linkPrefix}how-the-internet-has-grown`}
              title="How The Internet Has Grown"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>in an article below, journalist James Ball explains how the internet’s early infrastructure now has to support a network of billions – leaving it bursting at the seams</TransitionLink>
            ).</p>

          <p>The early years of the web were a whirlwind: new counter-culture collectives, grassroots communities and early start-up companies sprung up everywhere, each relishing the unbound opportunities of the new tech. The internet was to be completely open and free, with no control from above.</p>

          <p>The spirit of those days is best captured in the late John Perry Barlow’s 1996 <a href="https://www.eff.org/cyberspace-independence" target="_blank" rel="noopener noreferrer">Declaration of the Independence of Cyberspace</a>. The Declaration was largely a response to increased government interference on the web. While early internet pioneers feared centralised government control, few realised that this free-for-all techno-libertarian worldview also sowed the seeds for the extreme corporate centralisation of the internet we see today.&nbsp;
            <TransitionLink
              to={`${linkPrefix}brief-history-of-the-internet`}
              title="A Brief History of the Internet and Internet Culture"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Author Hussein Kesvani’s piece guides us through this history</TransitionLink>
            .
          </p>

          <p>Today’s children have inherited an internet built by generations before them, yet continue to use it in new and imaginative ways.&nbsp;
            <TransitionLink
              to={`${linkPrefix}draw-the-internet`}
              title="Draw The Internet: The web as seen by the world's school children"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Artist Dries de Roeck asked school children in both Belgium and Senegal to “draw the internet”</TransitionLink>
            . Older children frequently drew YouTube and Instagram logos,  reinforcing how centralised the internet has become. How did we get here? To understand our present predicament, we must learn from the decisions we made in the past.</p>

          <p>Also included in this chapter:&nbsp;
            <TransitionLink
              to={`${linkPrefix}jimmy-wales`}
              title="Wikipedia Founder Jimmy Wales Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>our interview with Wikipedia founder Jimmy Wales</TransitionLink>
            , and&nbsp;
            <TransitionLink
              to={`${linkPrefix}living-coral`}
              title="Living Coral: The Internet, Animated"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Australian artist Xanthe Dobbie’s gifs visualising the abundance of the “perpetual internet”</TransitionLink>
            .</p>
        </div>
      )
    } else if (heading === "Power") {
      return (
        <div>
          <p>Increasingly, power over who gets to create, shape, and profit from the internet is concentrated in an ever smaller number of hands.</p>

          <p>The sheer power tech giants wield – from Amazon to Facebook to Alibaba – needs no introduction. The data barons of the new Gilded Age (
            <TransitionLink
              to={`${linkPrefix}frank-pasquale`}
              title="Law Professor Frank Pasquale Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>see our interview with law scholar Frank Pasquale</TransitionLink>
            ) control most layers of the internet and are continuously spreading their reach. Amazon is buying anything from <a href="https://www.theguardian.com/business/2017/jun/16/amazon-buy-whole-foods-market-organic-food-fresh" target="_blank" rel="noopener noreferrer">brick-and-mortar supermarkets</a> to healthcare companies. Google’s parent company Alphabet is redeveloping large swaths of <a href="https://www.sidewalklabs.com/" target="_blank" rel="noopener noreferrer">Toronto’s waterfront</a> and is allegedly helping the US and <a href="https://www.reuters.com/article/us-usa-china-google/googles-work-in-china-benefiting-chinas-military-u-s-general-idUSKCN1QV296" target="_blank" rel="noopener noreferrer">Chinese armies</a> build AI-based military technology.</p>

          <p>It is often incredibly difficult to keep these companies in check, as the lack of concrete action against Facebook in the wake of the Cambridge Analytica scandal has shown.&nbsp;
            <TransitionLink
              to={`${linkPrefix}the-cycle-of-surveillance-capitalism`}
              title="The Cycle of Surveillance Capitalism"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Below, artist Roos Groothuizen has drawn diagrams that illustrate the repetitive nature of such scandals</TransitionLink>
            .</p>

          <p>And yet the tide may just be turning. While some governments aggressively attempt to claw back power, others fight to empower citizens via the internet.&nbsp;
            <TransitionLink
              to={`${linkPrefix}audrey-tang`}
              title="Taiwan Digital Minister Audrey Tang Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Take hacker and activist Audrey Tang, for example, who as the first digital minister of Taiwan is an important champion of digital democracy, interviewed below.</TransitionLink>
            </p>

          <p>Ordinary people are increasingly reclaiming control. Communities from <a href="https://en.wikipedia.org/wiki/Guifi.net" target="_blank" rel="noopener noreferrer">Catalunya</a> to <a href="https://motherboard.vice.com/en_us/article/kz3xyz/detroit-mesh-network" target="_blank" rel="noopener noreferrer">Detroit</a> are building their own local internet networks, cities are fighting back against <a href="https://citiesfordigitalrights.org/" target="_blank" rel="noopener noreferrer">Airbnb</a>, and activist Berliners sick of gentrification successfully <a href="https://fuckoffgoogle.de/" target="_blank" rel="noopener noreferrer">managed to keep Google out</a> of Kreuzberg. Power comes in many different forms. To rebuild the internet, we must understand power – who has it, and who gets to keep it.</p>

          <p>Also below, see&nbsp;
            <TransitionLink
              to={`${linkPrefix}shoshana-zuboff`}
              title="Professor Shoshana Zuboff on Surveillance Capitalism Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>our interview with Professor Shoshana Zuboff on the topic of surveillance capitalism</TransitionLink>
            , and&nbsp;
            <TransitionLink
              to={`${linkPrefix}clare`}
              title="Clare: A short story about a failing feminist initiative"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>a dystopian short story by author Caroline O'Donoghue</TransitionLink>
              , who explores what happens when power corrupts a feminist internet initiative
            .</p>
        </div>
      )
    } else if (heading === "Shift") {
      return (
        <div>
          <p>Over the past few decades, the internet has become the most important infrastructure of our time, radically rewiring how our societies work and how we interact.&nbsp;
            <TransitionLink
              to={`${linkPrefix}thoughts-on-todays-internet`}
              title="Watch: What do the general public think about the Internet?"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>We asked the BBC to find out how ordinary people feel about these changes – watch their varied answers in the video below</TransitionLink>
          .</p>

          <p>It is no longer possible to live our daily lives without technology both monitoring and influencing our choices. Sensors in our supermarkets track the products we look at; our bus trips are logged and the data sold to third party apps; and the restaurants we choose are dictated by ratings on our favourite apps.&nbsp;
            <TransitionLink
              to={`${linkPrefix}oobah-butler`}
              title="Internet Prankster Oobah Butler Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Our interview with Oobah Butler</TransitionLink>
            , who famously got a completely fake restaurant to the <a href="https://www.vice.com/en_uk/article/434gqw/i-made-my-shed-the-top-rated-restaurant-on-tripadvisor" target="_blank" rel="noopener noreferrer">top spot</a> on TripAdvisor, shows the flaws in the systems that influence us.</p>

          <p>There is now even an entire brand of marketing dedicated to influencing our decisions online.&nbsp;
          <TransitionLink
            to={`${linkPrefix}sara-melotti`}
            title="Instagram Influencer Sara Melotti Q&A"
            exit={{trigger: triggerOut, length: length, zIndex: 2 }}
            entry={{length: length, delay: length, zIndex: 1}}>Below, Instagrammer Sara Melotti exposes the truth about #travel #influencers, and explains how social media shapes our identities and perceptions, often to the detriment of our mental health</TransitionLink>
          .</p>

          <p>This race to get the most likes, the best reviews, and game the algorithm is not only bad for us, it is also making our societies more bland, catering to the average taste instead of emphasising originality (ever wondered why coffee shops from Tokyo to Birmingham now all look the same?). If we want to steer the direction of the internet, rather than be steered by it, we need to better understand how these invisible forces are shaping us.</p>

          <p>Also check out&nbsp;
            <TransitionLink
              to={`${linkPrefix}laney-and-sammy-griner`}
              title="Success Kid Meme Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>our interview with Sammy Griner, who you might know as the “Success Kid”</TransitionLink>
            , and&nbsp;
            <TransitionLink
              to={`${linkPrefix}marleen-stikker`}
              title="Internet Pioneer Marleen Stikker Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>our Q&A with early internet pioneer Marleen Stikker</TransitionLink>
            .</p>
        </div>
      )
    } else if (heading === "Delete") {
      return (
        <div>
          <p>The internet was supposed to be the ultimate celebration of diversity, a platform without gatekeepers that exposed us to new ideas. Indeed, the internet has empowered many disenfranchised voices: it gave us #blacklivesmatter and #MeToo, but also provided a bigger platform to anti-vaxxers, the Flat Earth Society and #QAnon.&nbsp;
            <TransitionLink
              to={`${linkPrefix}whitney-phillips`}
              title="Digital Culture Expert Whitney Phillips Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>In our interview with academic Whitney Phillips, we learn more about how the internet has become a tool for radicalisation and media manipulation</TransitionLink>
            .</p>

          <p>As the internet has driven further polarisation, our societies have arguably become fractured.&nbsp;
            <TransitionLink
              to={`${linkPrefix}fair-arbiters`}
              title="Fair Arbiters: A short story about moderating the Internet"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Author Rebecca Lynch ponders how we might fix society’s divisions in her short story “Fair Arbiters”</TransitionLink>
            .</p>

          <p>Yet the problem is not just the voices we have given a platform to, but also the voices the internet is inadvertently silencing.&nbsp;
            <TransitionLink
              to={`${linkPrefix}imagining-a-multilingual-cyberspace`}
              title="Imagining a Multilingual Cyberspace"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>In a comprehensive essay, Isabelle Zaugg explores the phenomenon of digital language extinction – what happens when your language isn’t supported by apps, websites, keyboards, and spell-checks</TransitionLink>
            . She offers solutions for a multilingual cyberspace.</p>

          <p>Language is just one aspect of identity.&nbsp;
            <TransitionLink
              to={`${linkPrefix}in-the-wilderness-years-of-photo-storage`}
              title="The Big Picture: What we lost in the wilderness years of photo storage"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Journalist Jessica Furseth’s personal piece explores how we lose our memories online</TransitionLink>
            . When we upload so many elements of ourselves to private platforms, what happens when these platforms disappear or change their rules?</p>

            <p>Also read&nbsp;
              <TransitionLink
                to={`${linkPrefix}saskia-sassen`}
                title="Sociologist Saskia Sassen"
                exit={{trigger: triggerOut, length: length, zIndex: 2 }}
                entry={{length: length, delay: length, zIndex: 1}}>our interview with world-renowned sociologist Saskia Sassen, on the impact of the internet on local communities</TransitionLink>
            .</p>
        </div>
      )
    } else if (heading === "Alt") {
      return (
        <div>
          <p>This February, we reached another important internet milestone: more than half of the world’s population is now online. Soon, another billion will join us. But though the internet’s user base is growing ever larger and more diverse, the number of internets available for us to choose from is actually shrinking.</p>

          <p>We find ourselves stuck between two dominant narratives: a Silicon Valley model, where large companies rule supreme, and an emerging Chinese model, where the government is in charge.&nbsp;
            <TransitionLink
              to={`${linkPrefix}kaiser-kuo`}
              title="Journalist Kaiser Kuo Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>In our Q&A with commentator (and heavy metal artist) Kaiser Kuo, we learn more about China’s rise to a technology superpower</TransitionLink>
            .</p>

          <p>But between Big Tech and Beijing, where does that leave the rest of us? Can we offer the next billion internet users something else?&nbsp;
            <TransitionLink
              to={`${linkPrefix}ifu-elimnyama-the-dark-cloud`}
              title="Ifu Elimnyama, The Dark Cloud: an experimental video"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>In an experimental video, South African artist Russel Hlongwane places Zulu cosmology, mysticism and sacred rituals within a digital framework to present an alternative view of the internet</TransitionLink>
            .</p>

          <p>There are other roads we didn’t take: what if we had decided to optimise for public good rather than for profit? What if it wasn’t California that had set the tone, but some other part of the world?&nbsp;
            <TransitionLink
              to={`${linkPrefix}what-post-soviet-internet-can-teach-us`}
              title="What the post-Soviet internet can teach us about the future"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Academic Polina Kolozaridi’s piece looks at the internets that emerged all over Russia in the early Post-Soviet era</TransitionLink>
            . As we try to conceive of new narratives for the internet, what can we learn from these alternative visions?</p>

          <p>Also below,&nbsp;
            <TransitionLink
              to={`${linkPrefix}why-we-must-change-our-mindsets-to-change-the-internet`}
              title="Why we must change our mindsets to change the internet"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>an article exploring collective intelligence by Chief Executive of Nesta, Geoff Mulgan</TransitionLink>
            .</p>
        </div>
      )
    } else if (heading === "Escape") {
      return (
        <div>
          <p>Though the current trajectory of the internet does not seem to be an overwhelmingly positive one, the future has not yet been written. We can still take a different path.</p>

          <p>It is all too easy to conceive of dystopian futures: automation threatens to take away our jobs, facial recognition systems will track and rate our every move. As governments increasingly see control over the internet as key to their survival in the 21st century, the risk of geopolitical conflict rises. Great powers will battle over who gets to be the next technology superpower – to quote Vladimir Putin, “Whoever controls AI, will rule the world”. There is potential for cyber war or a breakdown of the global internet altogether (welcome to the  splinternet), but there is also potential for harmony.&nbsp;
            <TransitionLink
              to={`${linkPrefix}toomas-hendrik-ilves`}
              title="Former Estonian President Toomas Hendrik Ilves Q&A"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Former Estonian president Toomas Hendrik Ilves discusses how the internet can allow us to transcend borders</TransitionLink>
            .</p>

          <p>No developments are inevitable. Rather than allowing ourselves to be petrified by dystopian scenarios, we must instead take charge of them.&nbsp;
            <TransitionLink
              to={`${linkPrefix}field-guide-to-the-living-internet`}
              title="A field guide to the living internet"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Below, engineer Udit Vira has imagined the internet as a living entity to challenge our perceptions of the web</TransitionLink>
            .</p>

          <p>We must allow ourselves to imagine what a better world would look like, and art is invaluable here.&nbsp;
            <TransitionLink
              to={`${linkPrefix}first-lines`}
              title="First Lines: A Poem"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>Writer Sarah Westcott has written a poem about the internet by weaving together British school children’s perceptions of the internet</TransitionLink>
            &nbsp;while&nbsp;
            <TransitionLink
              to={`${linkPrefix}the-eulogy-that-never-was`}
              title="The eulogy that never was: a short story on the death of the internet"
              exit={{trigger: triggerOut, length: length, zIndex: 2 }}
              entry={{length: length, delay: length, zIndex: 1}}>PhD student Maciej Kuziemski has written a short story eulogising the World Wide Web</TransitionLink>
            .</p>

          <p>We hope the stories and artworks in this collection have made you feel more hopeful and able to take charge of the future.</p>

          <p>Finally, read&nbsp;
          <TransitionLink
            to={`${linkPrefix}olivier-bringer`}
            title="NGI Head Olivier Bringer Q&A"
            exit={{trigger: triggerOut, length: length, zIndex: 2 }}
            entry={{length: length, delay: length, zIndex: 1}}>our Q&A with NGI Head Olivier Bringer on how the Next Generation Internet initiative will bring around concrete changes to improve the internet for all</TransitionLink>
          .</p>
        </div>
      )
    }
  }
}

ChapterIntroText.contextType = Consumer

export default ChapterIntroText