import React from "react"
import Consumer from "../../layouts/Context"
import { InView } from 'react-intersection-observer'
import "./ForeWord.scss"

// Components
import ArrowBtn from "../ArrowBtn/ArrowBtn"

// CSS
import "../Btn/Btn.scss"

class ForeWord extends React.Component {
  constructor(props) {
    super(props)

    this.showBtn = this.props.showBtn
    this.hideBtn = this.props.hideBtn
    this.show = this.props.show
    this.hide = this.props.hide
  }

  scrolled = (inView, entry) => {
    if (window.innerWidth < 768 && inView) {
      this.showBtn()
    } else if (window.innerWidth < 768 && !inView && entry.boundingClientRect.y > 0) {
      this.hideBtn()
      this.context.set({
        homeChapter: 1
      })
    }  else if (window.innerWidth > 768 && !inView && this.props.menuOpen) {
      this.hide()
    } else if (window.innerWidth > 768 && inView && !this.props.menuOpen) {
      this.show()
      this.context.set({
        homeChapter: 1
      })
    } else if (window.innerWidth > 768 && inView) {
      this.context.set({
        homeChapter: 1
      })
    }
  }

  // exitHome = (node, e, exit, entry) => {
  //   this.context.set({
  //     homeInit: true,
  //     homeScroll: window.pageYOffset
  //   })

  //   TweenMax.to(this.props.pageWipe, .75, {ease: Power3.easeOut, x: "0"})
  // }

  render() {

    return (
      <div className="chapters" id="chapter-foreword">
        <div className="container">
          <div className="cols flex">

            <InView
              threshold={.1}
              triggerOnce={true}
            >
              {({ inView, ref }) => (
                <div className="chapters__col chapters__col--content col m12 t6 d4" ref={ref} data-anim={inView} tabIndex="0">
                  <h2>Foreword</h2>

                  <p>In March 2019, the World Wide Web turned thirty, and October will mark the fiftieth anniversary of the internet itself. These anniversaries offer us an important opportunity to reflect on the internet’s history, but also a chance to ponder its future.</p>

                  <p>While early internet pioneers dreamed of an internet that would be open, free and decentralised, the story of the internet today is mostly a story of loss of control. Just a handful of companies determine what we read, see and buy, where we work and where we live, who we vote for, who we love, and who we are. Many of us feel increasingly uneasy about these developments. We live in a world where new technologies happen <em>to</em> us; the average person has very little agency to change things within the current political and economic parameters.</p>

                  <p>Yet things don’t have to be this way. In a time where the future of the internet is usually painted as bleak and uncertain, we need positive visions about where we go next.</p>

                  <p>As part of the Next Generation Internet (NGI) initiative – the European Commission’s new flagship programme working on building a more democratic, inclusive and resilient internet – we have created this “visions book”, a collection of essays, short stories, poetry and artworks from over 30 contributors from 15 countries and five continents. Each contributor has a unique background, as most were selected via an open call for submissions held last autumn. As such, the book collects both established and emerging voices, all reflecting on the same crucial questions: where did we come from, but more importantly, where do we go next?</p>

                  <p>The NGI hopes to empower everyone to take active control in shaping the future: the internet does not just belong to those who hold power today, but to all of us.</p>

                  <a href="/text" className="btn btn--secondary" title="View Enhanced Accessibility Mode" tabIndex="0">
                    <span className="btn__inner">
                      <span>
                        <span>A</span><span>c</span><span>c</span><span>e</span><span>s</span><span>s</span><span>i</span><span>b</span><span>l</span><span>e</span>&nbsp;
                        <span>V</span><span>e</span><span>r</span><span>s</span><span>i</span><span>o</span><span>n</span>
                      </span>
                      <svg className="btn-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                        <path fill="none" stroke="#1125aa" strokeMiterlimit="10" strokeWidth="2" d="M.68.7L16 16"/>
                        <path fill="none" stroke="#1125aa" strokeMiterlimit="10" strokeWidth="2" d="M16 17V4"/>
                        <path fill="none" stroke="#1125aa" strokeMiterlimit="10" strokeWidth="2" d="M17 16H4"/>
                        <path fill="none" stroke="#1125aa" strokeMiterlimit="10" strokeWidth="2" d="M.68.7L16 16"/>
                        <path fill="none" stroke="#1125aa" strokeMiterlimit="10" strokeWidth="2" d="M16 17V4"/>
                        <path fill="none" stroke="#1125aa" strokeMiterlimit="10" strokeWidth="2" d="M17 16H4"/>
                      </svg>
                    </span>
                  </a>

                  <ArrowBtn label="View Chapters" target="chapter-timeline" modifier="down" direction="down" title="text" anchor={true} anchorSpeed={1000} />
                </div>
              )}
            </InView>

            <InView
              threshold={.5}
              onChange={this.scrolled}
            >
              {({ inView, ref }) => (
                <div className="chapters__col chapters__col--list col m12 t6 d6" ref={ref} />
              )}
            </InView>

          </div>
        </div>
      </div>
    )
  }
}

ForeWord.contextType = Consumer

export default ForeWord