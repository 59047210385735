import React from "react"
import Nesta from "./images/nesta.svg"
import NGI from "./images/ngi.png"
import "./Logos.scss"

const Logos = ({ isVisible }) => {

  return (
    <div className="logos flex" data-anim={isVisible}>
      <div>
        <img src={Nesta} width="62" height="53" alt="Nesta" />
      </div>
      <div>
        <img src={NGI} width="62" height="62" alt="Next Generation Internet" />
      </div>
    </div>
  )
}

export default Logos