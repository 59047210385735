import React from "react"
import Hammer from 'react-hammerjs'
import { InView } from 'react-intersection-observer'
import { trackWindowScroll } from 'react-lazy-load-image-component'
import "./StoryList.scss"
import _ from 'lodash'

// Components
import StoryListItem from "../StoryListItem/StoryListItem"

class StoryList extends React.Component {
  constructor(props) {
    super(props)

    this.current = 0
    this.eventActive = false

    this.state = ({
      offsetPercentage: 0,
      maxOffset: 3
    })

    this.debouncedReset = _.debounce(this.reset, 500)
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedReset)

    this.setOffset()
  }

  componentDidUpdate() {
    // console.log('Updated StoryList')
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedReset)
  }

  getOffset = () => {
    let windowWidth = window.innerWidth,
        device

    device = (windowWidth < 768 ?
      1 :
      (windowWidth < 1240 ?
        2 :
        3
      )
    )

    switch(device) {
      case 1:
        this.singleOffset = -100
        this.maxOffset = 1
        break;
      case 2:
        this.singleOffset = -50
        this.maxOffset = 2
        break;
      default:
        this.singleOffset = -32.5
        this.maxOffset = 3
    }
  }

  previous = () => {
    if (this.current === 0 || this.props.access) return

    this.current--
    this.setOffset()
  }

  next = () => {
    if (this.current === this.props.stories.length - this.state.maxOffset || this.props.access) return

    if (!this.eventActive) {
      this.eventActive = true
    }

    this.current++
    this.setOffset()
  }

  setOffset = () => {
    this.getOffset()
    let newPx = this.singleOffset * this.current

    this.setState({
      offsetPercentage: newPx,
      maxOffset: this.maxOffset
    })
  }

  reset = () => {
    this.eventActive = false
    this.current = 0
    this.getOffset()
    this.setState({
      offsetPercentage: 0,
      maxOffset: this.maxOffset
    })
  }

  render() {
    const {stories} = this.props,
          {offsetPercentage} = this.state,
          {pageWipe} = this.props,
          {access} = this.props

    let navRequired = (this.props.stories.length > this.state.maxOffset)

    let prevActive = (this.current > 0 ? true : false),
        nextActive = (this.current < this.props.stories.length - this.state.maxOffset ? true : false)

    return (
      <Hammer onSwipeLeft={this.next} onSwipeRight={this.previous}>
        <div className="chapter-stories container">
          <InView
            threshold={[.2]}
            triggerOnce={true}
          >
            {({ inView, ref }) => (
              <div className="chapter-stories__container" data-anim={inView} ref={ref}>
                <h3 tabIndex="0">Stories &amp; interviews</h3>

                <div className="story-list cols flex">
                  <div className="story-list__col col m10 d10">
                    <div className="story-list__runner" style={{transform: `translate3d(${offsetPercentage}%, 0, 0)`}}>
                      {stories.map(({ node }, index) => {
                        const props = node.frontmatter

                        return (
                          <StoryListItem key={props.title} slug={node.fields.slug} pageWipe={pageWipe} scroll={trackWindowScroll} access={access} {...props} />
                        )
                      })}
                    </div>
                  </div>
                </div>

                {
                  navRequired && !access && (

                  <div className="chapter-stories__nav">
                    <div className="chapter-stories__arrow chapter-stories__arrow--prev" data-active={prevActive} onClick={this.previous} aria-label="Previous Item" title="Previous Item">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 40"><path d="M49 38.6l19.68-20L70 20 50.33 40zm0-37.2L50.33 0 70 20l-1.34 1.4zM0 19h68v2H0z"/></svg>
                    </div>

                    <div className="chapter-stories__arrow chapter-stories__arrow--next" data-active={nextActive} onClick={this.next} aria-label="Next Item" title="Next Item">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 40"><path d="M49 38.6l19.68-20L70 20 50.33 40zm0-37.2L50.33 0 70 20l-1.34 1.4zM0 19h68v2H0z"/></svg>
                    </div>
                  </div>
                )}

              </div>
            )}
          </InView>
        </div>
      </Hammer>
    )
  }
}

export default StoryList