import React from "react"
import Consumer from "../../layouts/Context"
import { TweenMax, Power3 } from "gsap/TweenMax"
import TransitionLink from 'gatsby-plugin-transition-link'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import StoryThumb from './images/story-thumb.jpg'
import "./StoryListItem.scss"

class StoryListItem extends React.Component {
  exitHome = (node, e, exit, entry) => {
    this.context.set({
      homeInit: true,
      homeScroll: window.pageYOffset
    })

    TweenMax.to(this.props.pageWipe, .75, {ease: Power3.easeOut, x: "0"})
  }

  render() {
    const {slug} = this.props,
          {title} = this.props,
          {author} = this.props,
          {thumb} = this.props,
          {scrollPosition} = this.props,
          {access} = this.props

    const placeholderElement = (
      <img
        alt={title}
        height={340}
        src={StoryThumb}
        width={340}
      />
    )

    return (
      <div className="story-list__item">
        <div className="story-list__item-image">
          <div className="story-list__item-image-inner">
            {
              access && (
                <TransitionLink
                  to={`/text${slug}`}
                  title={title}
                  exit={{
                    length: .2,
                    zIndex: 2
                  }}
                  entry={{
                    length: .2,
                    delay: .2,
                    zIndex: 1
                  }}
                >
                  <LazyLoadImage
                    alt={title}
                    height={340}
                    placeholder={placeholderElement}
                    scrollPosition={scrollPosition}
                    src={thumb ? thumb.publicURL : StoryThumb}
                    width={340}
                    threshold={1000}
                  />
                </TransitionLink>
              )
            }
            {
              !access && (
                <TransitionLink
                  to={slug}
                  title={title}
                  exit={{
                    trigger: ({node, e, exit, entry}) => this.exitHome(node, e, exit, entry),
                    length: .75,
                    zIndex: 2
                  }}
                  entry={{
                    length: .75,
                    delay: .75,
                    zIndex: 1
                  }}
                >
                  <LazyLoadImage
                    alt={title}
                    height={340}
                    placeholder={placeholderElement}
                    scrollPosition={scrollPosition}
                    src={thumb ? thumb.publicURL : StoryThumb}
                    width={340}
                    threshold={1000}
                  />
                </TransitionLink>
              )
            }
          </div>
        </div>

        <div className="story-list__item-content">

          <TransitionLink
            className="h5"
            to={slug}
            title={title}
            exit={{
              trigger: ({node, e, exit, entry}) => this.exitHome(node, e, exit, entry),
              length: .75,
              zIndex: 2
            }}
            entry={{
              length: .75,
              delay: .75,
              zIndex: 1
            }}
          >
            {title}
          </TransitionLink>
          <h5>{author}</h5>
        </div>
      </div>
    )
  }
}

StoryListItem.contextType = Consumer

export default StoryListItem
